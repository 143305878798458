import { Card, CardContent } from "@material-ui/core";
import { formatDate, parseDate } from "@superdispatch/dates";
import { Stack } from "@superdispatch/ui";
import { DescriptionItem, TextBox } from "@superdispatch/ui-lab";
import { formatCurrency } from "shared/helpers/IntlHelpers";
import styled from "styled-components";
import { RecentMovedLoadDTO } from "../../../data/CarrierPayDTO";
import { CalendarOut } from "../../../icons/CalendarOut";
import { PostingVehicle } from "./LoadVehicle";
import { LoadWaypoints } from "./LoadWaypoints";
import { PostingVehiclesSummary } from "./PostingVehiclesSummary";

const StyledCardContent = styled(CardContent)`
  padding: 12px 16px;

  display: grid;
  grid-row-gap: 8px;
  grid-column-gap: 16px;
  grid-template-columns: repeat(3, minmax(0, 1fr)) auto;

  @media (max-width: 1350px) {
    grid-template-rows: repeat(2, minmax(0, 1fr));
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (max-width: 1000px) {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: 1fr;
  }
`;

export function RecentMovedLoad({ load }: { load: RecentMovedLoadDTO }) {
  return (
    <Card>
      <StyledCardContent>
        <LoadWaypoints load={load} />

        <Stack space="xsmall">
          {load.vehicles[0] && <PostingVehicle vehicle={load.vehicles[0]} />}

          {load.vehicles.length > 1 && (
            <PostingVehiclesSummary
              title={`+ ${load.vehicles.length - 1} more`}
              vehicles={load.vehicles.slice(1)}
            />
          )}
        </Stack>

        <Stack>
          <DescriptionItem label="Dispatch" icon={<CalendarOut />}>
            {formatDate(
              parseDate(load.dispatched_date, {
                format: "DateISO",
              }),
              { variant: "Date" }
            )}
          </DescriptionItem>

          <DescriptionItem label="Delivery" icon={<CalendarOut />}>
            {formatDate(
              parseDate(load.delivered_date, {
                format: "DateISO",
              }),
              { variant: "Date" }
            )}
          </DescriptionItem>
        </Stack>

        <Stack space="xxsmall" align={["left", "left", "right"]}>
          <TextBox variant="heading-4">{formatCurrency(load.price)}</TextBox>
          <TextBox color="secondary">
            {formatCurrency(load.price_per_mile_per_vehicle, {
              maximumFractionDigits: 2,
            })}
            /mi
          </TextBox>
        </Stack>
      </StyledCardContent>
    </Card>
  );
}
