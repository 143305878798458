import { formatVehicleType } from "@superdispatch/sdk";
import { Column, Columns, OverflowText, Stack } from "@superdispatch/ui";
import { Box, TextBox } from "@superdispatch/ui-lab";
import { joinStrings } from "shared/utils/StringUtils";
import { RecentMovedLoadVehicleDTO } from "../../../data/CarrierPayDTO";
import { VehicleConditionIcon } from "./VehicleConditionIcon";

export function formatVehicleTitle({
  year,
  make,
  model,
}: RecentMovedLoadVehicleDTO) {
  return joinStrings(" ", year, make, model);
}

interface PostingVehicleProps {
  vehicle: RecentMovedLoadVehicleDTO;
}

export const PostingVehicle = ({
  vehicle,
  vehicle: { type, is_inoperable, requires_enclosed_trailer },
}: PostingVehicleProps) => {
  const title = formatVehicleTitle(vehicle);

  return (
    <Stack space="none">
      <Columns space="xxsmall" align="center">
        <Column width="adaptive">
          <Box maxWidth="200px">
            <OverflowText aria-label="Vehicle title" disableUnderline={true}>
              {title}
            </OverflowText>
          </Box>
        </Column>

        <Column width="content">
          <VehicleConditionIcon
            requiresEnclosedTrailer={requires_enclosed_trailer}
            isInoperable={is_inoperable}
          />
        </Column>
      </Columns>
      {type && (
        <TextBox aria-label="Vehicle type" variant="body-semibold">
          {formatVehicleType(type.toLowerCase())}
        </TextBox>
      )}
    </Stack>
  );
};
