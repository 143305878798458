import { User } from "@datadog/browser-core/src/domain/user/user.types";
import { datadogLogs } from "@datadog/browser-logs";

const urlMatchers = ["https://api.segment.io", "https://cdn.segment.com"];

const ignoreMatchers = ["Font Awesome Kit: Error"];

const errorMatchers = [
  "Error sending segment performance metrics",
  "Failed to load Analytics.js",
  "Load failed",
  "HTTPError: 403", // error from cloudflare
];

let lastSpanId: unknown;
let lastTraceId: unknown;

export function initMonitoring(service: string, appId: string) {
  try {
    datadogLogs.init({
      clientToken: "pubf1b3fd8bf6ce2148e6ce7eb8a7c80b3a",
      site: "datadoghq.com",
      forwardErrorsToLogs: true,
      sampleRate: 100,
      version: import.meta.env.VITE_APP_RELEASE,
      env: import.meta.env.VITE_APP_TARGET,
      service,
      forwardConsoleLogs: ["error"],
      beforeSend(log) {
        // inject trace into logs
        log.dd = { trace_id: lastTraceId, span_id: lastSpanId };

        for (const urlPart of urlMatchers) {
          if (log.http?.url.startsWith(urlPart)) {
            //skip logging this error
            return false;
          }
        }

        for (const ignoreMatcher of ignoreMatchers) {
          if (log.message.includes(ignoreMatcher)) {
            return false;
          }
        }

        //Transform all "Failed to fetch" errors to warnings
        if (log.http?.status_code === 0) {
          log.status = "warn";
        }

        if (log.http?.status_code === 403) {
          log.status = "warn";
        }

        for (const matchers of errorMatchers) {
          if (log.message.includes(matchers)) {
            log.status = "warn";
          }
        }

        return undefined;
      },
    });

    void initializeUserMonitor(service, appId);
  } catch (error: unknown) {
    //
  }
}

datadogLogs.onReady(() => {
  addLogContext("module_name", import.meta.env.VITE_APP_NAME);
});

export function identifyUser(user: User) {
  datadogLogs.setUser(user);
}

export async function initializeUserMonitor(service: string, appId: string) {
  if (import.meta.env.VITE_APP_TARGET === "local") {
    return;
  }

  return import("@datadog/browser-rum")
    .then(({ datadogRum }) => {
      datadogRum.init({
        service: service,
        applicationId: appId,
        clientToken: "pub48d9129774f408d47290d188c2f49784",
        site: "datadoghq.com",
        version: import.meta.env.VITE_APP_RELEASE,
        env: import.meta.env.VITE_APP_TARGET,
        sampleRate: 100,
        traceSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [
          "https://api.quoting.superdispatch.com",
          "https://api.staging.quoting.superdispatch.org",
        ],
        defaultPrivacyLevel: "mask-user-input",
        beforeSend(event) {
          lastTraceId = event._dd.trace_id;
          lastSpanId = event._dd.span_id;
        },
      });
    })
    .catch((error) => {
      logError(error, "DataDog RUM");
    });
}

export function addMonitoringAction(
  name: string,
  context?: Record<string, unknown>
) {
  void import("@datadog/browser-rum").then(({ datadogRum }) =>
    datadogRum.addAction(name, context)
  );
}

export function getDatadogLogger() {
  return datadogLogs.logger;
}

export function addLogContext(key: string, value: Record<string, unknown>) {
  return getDatadogLogger().addContext(`context.${key}`, value);
}

export function removeLogContext(key: string) {
  return getDatadogLogger().removeContext(`context.${key}`);
}

export function logInfo(message: string, messageContext?: Record<string, any>) {
  const logger = getDatadogLogger();
  logger.info(message, { message_context: messageContext });
}

export function logWarning(
  message: string,
  messageContext?: Record<string, any>
) {
  const logger = getDatadogLogger();
  logger.warn(message, { message_context: messageContext });
}

export function logError(
  error: unknown,
  source: string,
  messageContext?: Record<string, unknown>
) {
  const logger = getDatadogLogger();

  if (typeof error === "string") {
    logger.error(
      error,
      { message_context: messageContext, error_source: source },
      new Error(error)
    );
  } else if (error instanceof Error) {
    logger.error(
      error.message,
      { message_context: messageContext, error_source: source },
      error
    );
  } else {
    logger.error(
      "Unknown Error",
      {
        unknown_error: error,
        message_context: messageContext,
        error_source: source,
      },
      new Error("Unknown Error") // create inline Error to include stacktrace
    );
  }
}
